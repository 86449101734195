import resume from "../assets/pdf/resume.pdf";

export const headerData = {
  name: "Tazwar Islam",
  title: "Software Engineer",
  desciption:
    "I'm  a Software Engineer with 5+ years of experience.Let's collaborate and shape the digital landscape together!",
  image: "https://i.ibb.co/Gk4g6f3/bhodro.png",
  resumePdf: resume,
};
