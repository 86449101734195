export const experienceData = [
  {
    id: 1,
    company: "Younode Inc - Hokkaido, Japan (Remote)",
    jobtitle: "Software Engineer",
    startYear: "2023 (Jan)",
    endYear: "Continue",
  },
  {
    id: 2,
    company: "LinkStaff Co Ltd. - Tokyo, Japan (Remote)",
    jobtitle: "Software Engineer",
    startYear: "2022 (Jan)",
    endYear: "2022 (Dec)",
  },
  {
    id: 3,
    company: "Prothom ALo - Dhaka",
    jobtitle: "Full-Stack Developer",
    startYear: "2019 (Feb)",
    endYear: "2021 (Dec)",
  },
  {
    id: 4,
    company: "OyeBusy Technologies Ltd.",
    jobtitle: "FRONTEND DEVELOPER",
    startYear: "2018 (Jul)",
    endYear: "2019 (Jan)",
  },
  {
    id: 5,
    company: "IOM - Ukhiya, Cox's Bazar",
    jobtitle: "FIELD FACILITATOR",
    startYear: "2018 (Jan)",
    endYear: "2018 (Jun)",
  },
];
