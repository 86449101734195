import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// import { ThemeContext } from "./contexts/ThemeContext";
import { Main, BlogPage, ProjectPage } from "./pages";
import { BackToTop } from "./components";
import ScrollToTop from "./utils/ScrollToTop";
import ReactGA from "react-ga4";

import "./App.css";

function App() {
  // const { theme } = useContext(ThemeContext);
  ReactGA.initialize("G-NZ5189TDYB", { debug: true });
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home visit" });

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route
            path="/"
            exact
            component={Main}
          />
          <Route
            path="/blog"
            exact
            component={BlogPage}
          />
          <Route
            path="/projects"
            exact
            component={ProjectPage}
          />

          <Redirect to="/" />
        </Switch>
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
