export const educationData = [
  {
    id: 1,
    institution: "Internatinal Islamic University Chittagong",
    course: "BSc on Computer Science and Engineering",
    startYear: "2016",
    endYear: "2020",
  },
  {
    id: 2,
    institution: "Chittagong Sunshine College",
    course: "Higher Secondary School Certificate",
    startYear: "2013",
    endYear: "2015",
  },
  {
    id: 3,
    institution: "Teknaf Pilot High School",
    course: "Secondary School Certificate",
    startYear: "2013",
    endYear: "2015",
  },
];
