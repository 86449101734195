import palo from "../assets/svg/projects/paloss.png";
import haal from "../assets/svg/projects/haalss.png";
import pulp from "../assets/svg/projects/puplpss.jpg";
import four from "../assets/svg/projects/four.svg";
import five from "../assets/svg/projects/five.svg";
import six from "../assets/svg/projects/six.svg";
import seven from "../assets/svg/projects/seven.svg";
import eight from "../assets/svg/projects/eight.svg";

export const projectsData = [
  {
    id: 1,
    projectName: "THE DAILY PROTHOM ALO",
    projectDesc:
      "Enhanced Prothom Alo's frontend. Added features, resolved bugs, ensuring optimal UX for 12M+ monthly users across 200+ countries",
    tags: ["React", "Redux", "GitHub", "AWS", "Docker"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://www.prothomalo.com/",
    image: palo,
  },

  {
    id: 2,
    projectName: "PULP",
    projectDesc:
      "Contributed on PULP, a music lovers' social sharing app, using React Native, TypeScript, GraphQL, and gained mobile app and blockchain integration expertise",
    tags: ["REACT NATIVE", "TYPESCRIPT", "APOLLO"],
    // code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://pulp.mu/index.html",
    image: pulp,
  },
  {
    id: 3,
    projectName: "HAAL FASHION",
    projectDesc:
      "Crafted Haal Fashion's frontend using React JS, TypeScript, Redux, SASS, ensuring responsive design for diverse devices, and gaining valuable web app and UI development experience",
    tags: ["React", "Redux", "GitHub", "AWS", "Docker"],
    demo: "https://haal.fashion/",
    image: haal,
  },
  // {
  //     id: 4,
  //     projectName: 'Android Patient Tracker',
  //     projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
  //     tags: ['Flutter', 'Firebase'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: four
  // },
  // {
  //     id: 5,
  //     projectName: 'E-Commerce App',
  //     projectDesc: 'A Simple E-commerce application',
  //     tags: ['React Native', 'Firebase'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: five
  // },
  // {
  //     id: 6,
  //     projectName: 'Uber Lite',
  //     projectDesc: 'Uber clone',
  //     tags: ['Flutter'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: six
  // },
  // {
  //     id: 7,
  //     projectName: 'Stock Market App',
  //     projectDesc: 'A simple stock market API app',
  //     tags: ['React', 'Redux', 'Bootstrap'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: seven
  // },
  // {
  //     id: 8,
  //     projectName: 'Car Pooling System',
  //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
  //     tags: ['Flutter', 'React'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: eight
  // },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
