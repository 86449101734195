export const socialsData = {
  github: "https://github.com/tazwar9t62",
  facebook: "https://www.facebook.com/tazwar96",
  linkedIn: "https://www.linkedin.com/in/tazwar9t6/",
  //   instagram: "https://www.instagram.com/",
  // codepen: 'https://codepen.io/',
  twitter: "https://twitter.com/tazwar_96",
  //   reddit: "https://www.reddit.com/user/",
  //   blogger: "https://www.blogger.com/",
  medium: "https://medium.com/@",
  stackOverflow: "https://stackoverflow.com/users/",
  //   gitlab: "https://gitlab.com/",
  //   youtube: "https://youtube.com/",
};
